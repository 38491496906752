import { baseUrl } from '@/utils/env'
import axios, { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { Quote, QuoteDefaultValueSetDTO } from '@/models/dto/Quote'
import { FormattedPaymentMethod } from '@/models/FormattedPaymentMethod'
import { CompanyPaymentGateway } from '@/models/dto/CompanyPaymentGateway'

const httpService: HttpService = new HttpService()

export const quotesTableView = async ({ pageSize, page, filters }) =>
  httpService.get(
    `https://${baseUrl()}/quotes/customer?${
      (pageSize ? `pageSize=${pageSize}&` : '') +
      (page ? `page=${page}&` : '') +
      (filters ? `${filters}` : '')
    }`
  )

export const quoteById = async (quoteId) =>
  httpService.get(`https://${baseUrl()}/v2/quotes/${quoteId}`)

export const getQuoteClassifications = async (quoteId) => {
  const response: AxiosResponse<any> = await httpService.get(
    `https://${baseUrl()}/quotes/${quoteId}/classifications`
  )
  if (response && response.data && response.data.successful === true) {
    return response.data.classifications
  }
  if (response && response.data && response.data.message) {
    throw new Error(response.data.message)
  } else {
    throw new Error('Unspecified error while getting quote classifications.')
  }
}

export const createQuoteClassification = async (
  quoteId: number,
  classificationId: number
) => {
  const response = await axios.put(
    `https://${baseUrl()}/quotes/${quoteId}/classifications/${classificationId}`
  )
  if (response && response.data && response.data.successful === true) {
    return response
  }
  if (response && response.data && response.data.message) {
    throw new Error(response.data.message)
  } else {
    throw new Error('Unspecified error while creating quote classification.')
  }
}

export const deleteQuoteClassification = async (
  quoteId: number,
  classificationId: number
) => {
  const response = await axios.delete(
    `https://${baseUrl()}/quotes/${quoteId}/classifications/${classificationId}`
  )
  if (response && response.data && response.data.successful === true) {
    return response
  }
  if (response && response.data && response.data.message) {
    throw new Error(response.data.message)
  } else {
    throw new Error('Unspecified error while deleting quote classification.')
  }
}

export const updateQuoteClassifications = async (
  quoteId: number,
  newClassificationIds: number[]
) => {
  let existingClassificationIds: number[] = []
  ;(await getQuoteClassifications(quoteId)).map((c) => {
    existingClassificationIds.push(c.classificationId)
  })

  return Promise.all(
    // create new classifications
    newClassificationIds
      .filter((id) => !existingClassificationIds.includes(id))
      .map((classificationId) =>
        createQuoteClassification(quoteId, classificationId)
      )
      .concat(
        // delete classifications
        existingClassificationIds
          .filter((id) => !newClassificationIds.includes(id))
          .map((classificationId) =>
            deleteQuoteClassification(quoteId, classificationId)
          )
      )
  )
}

/**
   * Retrieves the quote for a given quote ID.
   * @param quoteId - The quote ID to retrieve the quote for.
   * @returns A promise that resolves to the quote for the specified quote ID.
   */
export const quoteInvoicePDFByHashAndCompanyId = async (quoteHash: string, companyId: number): Promise<AxiosResponse<string>> => {
  const url = `https://${baseUrl('pdf')}/pdf/quote/invoice/${quoteHash}/${companyId}`;
  return httpService.get(url)
}

export const convertQuote = async (
  quote: Quote,
  paymentInfo: {
    tokens: string[],
    paymentGateway: CompanyPaymentGateway
  } = null,
  paymentMethodData: FormattedPaymentMethod = {
    activeMethod: '',
    saveForFuturePayments: false,
    name: '',
    cardholderName: '',
    cardNumber: '',
    mask: '',
    securityCode: '',
    exp_date: '',
    expirationMonth: '',
    expirationYear: '',
    type_label: '',
    formattedExpiration: '',
    address: {
      name: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      postalCode: '',
      lat: 0,
      lng: 0,
      title: '',
      timeZone: '',
      country: '',
    },
  }
) => {
  let payload

  if (!quote) {
    throw new Error('Missing parameters in convertQuote()')
  }

  // Either we're dealing with manual conversion of a quote
  // when paying after services or we're dealing with checkout.
  // Whether we have a token should be a good indicator.
  if (paymentInfo) {
    delete paymentMethodData.cardNumber
    delete paymentMethodData.securityCode

    payload = {
      nonces: paymentInfo.tokens,
      payment_method: paymentMethodData.activeMethod,
      billing: paymentMethodData,
      payment_gateway: paymentInfo.paymentGateway,
    }
  } else {
    payload = {
      meta: {
        notes: 'Manual conversion',
      },
    }
  }

  const response = await axios.post(
    `https://${baseUrl()}/quotes/${quote.quoteId}/convert`,
    payload
  )
  if (response && response.data && response.data.successful === true) {
    return response
  }
  if (response && response.data && response.data.message) {
    throw new Error(response.data.message)
  } else {
    throw new Error('Unspecified error while converting quote.')
  }
}

export const sendQuoteBidInvoiceEmail = async (quoteId: number, companyId: number) => {
  const url = `https://${baseUrl()}/quotes/sendBidInvoiceEmail/${quoteId}/${companyId}`
  return axios.get(url)
}

export const shareQuote = async (quoteId: number, userId: number) => {
  const url = `https://${baseUrl()}/quotes/${quoteId}/share/${userId}`
  return httpService.post(url, {})
}

export const unshareQuote = async (quoteId: number, userId: number) => {
  const url = `https://${baseUrl()}/quotes/${quoteId}/unshare/${userId}`
  return httpService.post(url, {})
}

export const getQuoteDefaultsByCustomerIdAndContractId = (customerId: number, contractId: number): Promise<AxiosResponse<QuoteDefaultValueSetDTO>> => {
  const queryParams = new URLSearchParams()
  if (contractId) {
    queryParams.append("contractId", contractId.toString());
  }
  const url = `https://${baseUrl()}/quotes/${customerId}/defaults?${queryParams.toString()}`
  return httpService.get(url)
}
